export const environment = {
  production: true,
  development: false,
  firebaseConfig: {
    apiKey: 'AIzaSyDoEgRu1LcvQYnhjO1-SpI9wuyiE4kg4EE',
    authDomain: 'wiply-production.firebaseapp.com',
    projectId: 'wiply-production',
    storageBucket: 'wiply-production.appspot.com',
    messagingSenderId: '565326052650',
    appId: '1:565326052650:web:f646ac7e40b41d30767253',
  },
  // this is a service account
  serviceAccount: {
    type: 'service_account',
    project_id: 'wiply-production',
    private_key_id: 'fd35fb8bcf89c14d5fda4b174a406d45819bdb2a',
    private_key:
      '-----BEGIN PRIVATE KEY-----\nMIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQDrlSIhq/TDiebb\nKDH6rZApbgVSbvXPmY1SJ8KAZD92VNbRk+mjOhJ/VeP0RF9A0fhhbKFfheXGElsu\n5zSzlMy4dwzmahiquijaCBNEJXCadb6g1AtMEK4UKjxzLezeuQFqhKxK3lRvWAeS\n+oR1KtQjYGRFktKiTCwgytsVPGBNTlS4MYNaccLP2O91bTNXENb650vygSWXOObL\nQdoztPvwwKc1vKZTU4udvU42Rq2+h6pNR9pvDDXyWUTfL0qnkXcH3ZvRseRT4efL\n/9xUBN//KwIp1+TXsdHm70POJSL/nvN/XmFp1LTOInV/+G/SYzwCGAAJ53Zl79wU\nPcshjUTnAgMBAAECggEAHkYt+rHEZJSGLEfAFHzVd92BpZRI1oLfHF2rqyA235gL\neydM8QL3eZGqF6vpqcTR7IMvQb1NR+T3/tELfsByhooWCE4+GUKPGWXMRAejrtXO\nx5A4W3ARTtoWpp6SQBT6T5v5IC3uH9jqMEMtXnlAhcNPX3l8xAqR6SzodpQptAVL\n03mdVoJ83QjPGHHEHYU+Zn7jVOyWBnQX/MgfVeedREOLOC5wDzYSS5nxUTStxTc+\nBlcQW5KUJzDCbduKmAQXdu3uoxsOnUT8h2SRCciAHM9cQA/L9wwIc+CHlER39+3E\npPGZ5v4/amKvxwQvNvCmJfhHkVD2pNnc9pFrHytlkQKBgQD2BCUZVYLOjw2+In9/\nwAQOmiU235+AlvMXYZb9pncPTEff0iK7BQDVMYPrUSCKsy4GAL0520R4ci3Uxy3t\nvPWTErCsDPOTy87xFGPbOIysQbq2z+dW3O0ngpUJOKk19GPFZq8riDQ1sfUYmPQD\nqy0xV1l1IRz/1i+bElyIK1TmQwKBgQD1JJf4dDCwyYcKIpJfiOeUaGEAOjeTw9c2\ny5oUPMieZ8aMjLU1Pbj7T7R27ATK5b/MIZakoY1D+24zSCeG/67vO/UFItxLSxxU\n/vJ/+NTao1/ZE+eOcLT/BNEJA2WMAYMkSX5tu3G78dyvd/MHUuzvaa7AHZqewF6/\nUop1V2mmjQKBgBYzRY3Fs6okKp9PyaWjlDolGtqaz2/xLzqa6d812GSfA5VlBxFN\nYEfc0gS4K3GYvbx48Jp5+PHNi+k5mCFt/dMpypucB5b8u03yAxjTP46D1++LT1r9\nUMFR/VT5sftWdHJp08Df56Y4yt9PXiW+mrNrKH1zYYSMtXsO6x62mlC1AoGBANny\nENKpqmhio5aVKgGZ3BoLusHTAHp1ES/8UB5R9CMxLErlBm4xU/bK5C0epRwmqyC3\ndBEmOEwKi+035zyUsVisu6g3WBfwzV2b1MmF1ugTfDGpCqOO/LwF8jrLNEsHRSrL\n1IWGsxVfcM7oG7+VMGWiVeUj7MXbTeFXZxXwA1Q5AoGBAMRwTNOwk9c49cJ3M8ES\nR1rag1PIdAgFvx3WMRIzkI20mJ//gK8QA/2Vqy3qYwnzKUqKsAkTaOydyJ8D6WGp\ntFT9BBHqHkWXL+juR5F+REIKeEQhEXroRjvHi3TATC24xH+xlXTI3zjGFm9Gb03i\nZldmqmG+TPcoq51pW4FTvAsl\n-----END PRIVATE KEY-----\n',
    client_email:
      'firebase-adminsdk-i8kzt@wiply-production.iam.gserviceaccount.com',
    client_id: '105142553888147160170',
    auth_uri: 'https://accounts.google.com/o/oauth2/auth',
    token_uri: 'https://oauth2.googleapis.com/token',
    auth_provider_x509_cert_url: 'https://www.googleapis.com/oauth2/v1/certs',
    client_x509_cert_url:
      'https://www.googleapis.com/robot/v1/metadata/x509/firebase-adminsdk-i8kzt%40wiply-production.iam.gserviceaccount.com',
  },

  appsUrls: {
    client: 'https://dev.wiply.games/',
    designerDashboard: 'https://dashboard.wiplify.com',
    clientNext: 'wiplify.com',
  },
  gamesUrls: {
    puzzle: 'https://3dpuzzle.netlify.app/',
    cups: 'https://3cups.netlify.app/',
    hockey: 'https://table-hockey-wiply.netlify.app/',
    wheel: 'https://wheel-wiply.netlify.app/',
    ninja: 'https://ninja-game.netlify.app',
    quiz: 'https://wiply-quiz.netlify.app/',
    memory: 'https://wiply-momory-game.netlify.app/',
    wordle: 'https://wiply-wordle.netlify.app',
    tower: 'https://snazzy-kulfi-a0eb3e.netlify.app',
    gems: 'https://bucolic-sawine-dc39f9.netlify.app',
  },
  imagesUrls: {
    logo: 'https://www.wiplify.com/assets/images/wiply/logo-white.png',
  },
  api: 'https://api.wiplify.com/api/',
  adminUID: 'f7qlu9hpfyfwestehbfkglispg92',
  // gaID: 'G-80699GSM9R',
  gaID: 'UA-211863956-1',
  mail: {
    templates: {
      welcome: 'template_np47fko11',
      getCoupon: 'template_u8kogob',
    },
    userId: 'user_jnDjIpEcTAF1mkiOa3k1d',
    serviceId: 'service_lcw4bdk',
  },
  payPal: {
    id: 'AQa6R2SKnO1Em0QbvORG1fRIqbLILVXHg6XvlHHm6Gmphqubo9i9xBm2FvJuR9RPKMlLxSJKym7skRa-',
    plans: ['P-37J17167EG113922YMINXCAI', 'P-1J030481F9005702AMINXAIY'],
    url: 'https://www.paypal.com/',
  },
  inforyou:
    'Basic b21lcjExMTpiM2MwMjJkYi0zMjc4LTRjYTYtYmRhYy1kMjQyYzUzNTM5ZDQ=',
};
